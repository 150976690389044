import { ENVIRONMENT, LeagueConfig } from '@leagueplatform/core';
/**
 * @ts-ignore is used here due to @leagueplatform/legacy-locales being a
 * temporary measure that is served as a JS utility.
 */
// @ts-ignore
import { legacyStrings } from '@leagueplatform/legacy-locales';
import { LoadingSpinner, highmarkCommon } from '@highmark-web/highmark-common';
import { EN_STRING_OVERRIDES } from 'locales/sdk-string-overrides/en';
import {
  CLIENT_ID,
  MEMBER_SEARCH_AUTH0_CONNECTION,
  TENANT_ID,
} from './common/constants';
import { ASSETS_CONFIG } from './common/asset-config';
import { theme } from './theme/theme';

export const config: LeagueConfig = {
  core: {
    clientId: CLIENT_ID,
    tenantId: TENANT_ID,
    auth: {
      clientOptions: {
        domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
        client_id: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
        redirect_uri: window.location.origin,
        connection: MEMBER_SEARCH_AUTH0_CONNECTION,
      },
      // Number of hours for which a session can be inactive before user is prompted to log out/continue
      // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
      idleSessionLifetimeHours: 0.25,
    },
    api: {
      wsUrl: import.meta.env.VITE_API_URL as string,
      url: import.meta.env.VITE_REST_API_URL as string,
      legacyRestApi: import.meta.env.VITE_LEGACY_REST_API as string,
    },
    contentUrl: import.meta.env.VITE_CONTENT_SERVER_URL as string,
    appEnvironment: import.meta.env.VITE_ENV as ENVIRONMENT,
    customMethods: {
      handleLink: async (url: string) => {
        window.open(url, '_blank', 'noreferrer');
      },
    },
    i18n: {
      strings: {
        default: { ...legacyStrings.default, ...EN_STRING_OVERRIDES },
        en: { ...legacyStrings.en, ...EN_STRING_OVERRIDES },
      },
    },
    ui: {
      theme,
      components: {
        loading: { element: <LoadingSpinner />, text: false },
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.background.secondary',
        },
      },
    },
  },
  __internal: {
    shouldApplyCSSReset: true,
    shouldApplyLegacyGlobalStyles: true,
    shouldIgnoreDOMLangAttributeForLocale: true,
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
};

// These are SDK initializers that have not yet been migrated to the new Config API.
export const initializeNonConfigSDKs = () => {
  highmarkCommon.config({
    highmarkRestApiUrl: import.meta.env.VITE_HIGHMARK_REST_API_URL,
  });
};
