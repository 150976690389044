import { useIntl } from '@leagueplatform/locales';
import { useHistory } from 'react-router-dom';
import {
  GDSParagraphTextProps,
  GDSStyleObject,
  Icon,
  ParagraphText,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { MemberResponseObject } from 'types/members-api.types';
import { useMemberOfInterestStore } from 'store/member-of-interest-store';
import { APP_ROUTES, SHORT_DATE_FORMAT_UTC } from 'common/constants';

const StyledRowText = ({
  children,
  css,
  ...props
}: {
  children: React.ReactNode;
  css?: GDSStyleObject;
  props?: GDSParagraphTextProps<'p'>;
}) => (
  <ParagraphText
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    css={{
      flex: 3,
      marginBlockEnd: '$none !important',
    }}
  >
    {children}
  </ParagraphText>
);
export const SearchResultHeader = () => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      spacing="$half"
      css={{
        flexGrow: '1',
        width: '100%',
        marginBottom: '$half',
        padding: '0 $oneAndHalf',
      }}
    >
      <UtilityText size="xs" emphasis="subtle" css={{ flex: 3 }}>
        {formatMessage({ id: 'NAME' })}
      </UtilityText>
      <UtilityText size="xs" emphasis="subtle" css={{ flex: 3 }}>
        {formatMessage({ id: 'UMI' })}
      </UtilityText>
      <UtilityText size="xs" emphasis="subtle" css={{ flex: 3 }}>
        {formatMessage({ id: 'ECID' })}
      </UtilityText>
      <UtilityText size="xs" emphasis="subtle" css={{ flex: 3 }}>
        {formatMessage({ id: 'DATE_OF_BIRTH' })}
      </UtilityText>
      <UtilityText size="xs" emphasis="subtle" css={{ flex: 3 }}>
        {formatMessage({ id: 'RELATIONSHIP' })}
      </UtilityText>
    </StackLayout>
  );
};

export const SearchResultRow = ({
  memberDetails,
}: {
  memberDetails: MemberResponseObject;
}) => {
  const { formatDate } = useIntl();
  const history = useHistory();
  const { setCurrentMember } = useMemberOfInterestStore();

  return (
    <li key={memberDetails.ecId}>
      <StackLayout
        as="button"
        orientation="horizontal"
        horizontalAlignment="spaceBetween"
        verticalAlignment="center"
        onClick={() => {
          setCurrentMember(memberDetails);
          history.push(APP_ROUTES.MEMBER_DETAILS);
        }}
        spacing="$half"
        css={{
          backgroundColor: '$surfaceBackgroundPrimary',
          textAlign: 'start',
          flexGrow: '1',
          width: '100%',
          padding: '0 $oneAndHalf',
          minHeight: '80px',
          border: 'none',
          borderTop: '$onSurfaceBorderSubdued 1px solid',
          '&:hover': {
            backgroundColor: '$interactiveBackgroundHovered',
            cursor: 'pointer',
          },
          // so the tab focus outline stays on top
          '&:focus': {
            zIndex: 1,
          },
        }}
      >
        <StyledRowText>
          {`${memberDetails.firstName} ${memberDetails.lastName}`}
        </StyledRowText>
        <StyledRowText>{memberDetails.umi}</StyledRowText>
        <StyledRowText>{memberDetails.ecId}</StyledRowText>
        <StyledRowText>
          {formatDate(memberDetails.dob, SHORT_DATE_FORMAT_UTC)}
        </StyledRowText>

        <StackLayout
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          css={{ flex: 3 }}
        >
          <StyledRowText>{memberDetails.relationship}</StyledRowText>
          <Icon icon="interfaceChevronRight" />
        </StackLayout>
      </StackLayout>
    </li>
  );
};
