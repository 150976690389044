import React from 'react';
import League from '@leagueplatform/core';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  CSAuthSessionContainer,
  CSAuthSignIn,
  CSAuthSignOut,
  ERROR_TYPES,
  ErrorState,
  BrowserRouter,
} from '@highmark-web/highmark-common';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { MemberSearchPage } from 'pages/member-search/member-search.page';
import { MemberDetailsPage } from 'pages/member-details/member-details.page';
import { PrivateBasePage } from 'components/private-base-page/private-base-page.component';
import { APP_ROUTES } from 'common/constants';
import { initializeNonConfigSDKs, config } from './init-config';

initializeNonConfigSDKs();

export function App() {
  return (
    <BrowserRouter>
      <CompatRouter>
        <League.LeagueProvider config={config}>
          <ObservabilityErrorBoundary
            fallback={
              <ErrorState isFullPage errorType={ERROR_TYPES.API_ERROR} />
            }
            errorContext={{
              context: {
                Context: { location: 'Root App ErrorBoundary' },
              },
            }}
          >
            <Switch>
              <Route path={APP_ROUTES.SIGN_IN} component={CSAuthSignIn} />
              <Route path={APP_ROUTES.SIGN_OUT} component={CSAuthSignOut} />
              <CSAuthSessionContainer>
                <PrivateBasePage>
                  <Route
                    path={APP_ROUTES.MEMBER_SEARCH}
                    component={MemberSearchPage}
                  />
                  <Route
                    path={APP_ROUTES.MEMBER_DETAILS}
                    component={MemberDetailsPage}
                  />
                  <Route>
                    <Redirect to={APP_ROUTES.MEMBER_SEARCH} />
                  </Route>
                </PrivateBasePage>
              </CSAuthSessionContainer>
            </Switch>

            <ReactQueryDevtools />
          </ObservabilityErrorBoundary>
        </League.LeagueProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}
